



























































import { CreateElement } from "vue";
import { defineComponent, ref, Ref } from "@vue/composition-api";
import { ValidationProvider } from "vee-validate";
import { ValidationContext } from "vee-validate/dist/types/components/common";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

//import { useValidationHelper } from "@/hooks/useValidationHelper.hook";

export default defineComponent({
  name: "BaseSelect",
  components: {
    vSelect,
    ValidationProvider,
  },
  props: {
    title: {
      type: String,
    },
    noOptionsText: {
      type: String,
    },
    customErrors: {
      type: Array,
      default: () => [],
    },
    value: {
      type: [String, Number, Object],
      default: "",
    },
    rules: {
      type: [String, Object],
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    vid: {
      type: String,
      default: undefined,
    },
    hideDropdownIcon: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    /*const { generatedVid } = useValidationHelper({
      title: props.title,
      rules: props.rules,
      name: props.name,
      vid: props.vid,
    });*/

    const providerRef: Ref<ValidationContext | null> = ref(null);

    const OpenIndicator = ref({
      render: (createElement: CreateElement) =>
        createElement("span", {
          domProps: {
            innerHTML: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" class="w-5 ${
              props.hideDropdownIcon ? "hidden" : ""
            }"><path fill="none" stroke="var(--orange-500)" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M30 9L16 23 2 9" vector-effect="non-scaling-stroke"/></svg>`,
          },
        }),
    });

    return {
      //generatedVid,
      providerRef,
      OpenIndicator,
    };
  },
});
