var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BaseIconSlotWrapper',{scopedSlots:_vm._u([{key:"icon",fn:function(){return [_vm._t("icon")]},proxy:true}],null,true)},[(_vm.title)?_c('BaseLabel',[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e()],1),_c('ValidationProvider',{ref:"providerRef",attrs:{"rules":_vm.rules,"name":_vm.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',_vm._g(_vm._b({class:[
        'v-select',
        {
          vs__error: errors.length > 0 || _vm.customErrors.length > 0,
          'cursor-not-allowed pointer-events-none': _vm.$attrs.disabled,
        } ],attrs:{"value":_vm.value,"components":{ OpenIndicator: _vm.OpenIndicator },"clearable":!_vm.$attrs.disabled && _vm.$attrs.clearable},on:{"input":function($event){return _vm.$emit('input', $event)}},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,name){return {key:name,fn:function(slotData){return [_vm._t(name,null,null,slotData)]}}}),(_vm.noOptionsText)?{key:"no-options",fn:function(){return [_c('span',{staticClass:"text-sm text-center text-gray-500"},[_vm._v(_vm._s(_vm.noOptionsText))]),_c('BaseError',{staticClass:"mt-2 -mb-2",attrs:{"errors":_vm.customErrors.concat( errors)}})]},proxy:true}:null],null,true)},'v-select',_vm.$attrs,false),_vm.$listeners),[_vm._l((_vm.$slots),function(_,name){return _vm._t(name,null,{"slot":name})})],2),_c('BaseError',{attrs:{"errors":_vm.customErrors.concat( errors)}})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }